import React from 'react'
import * as validations from '../../../../utils/validations'
import TextField from '../../../molecules/fields/TextField'
import Value from '../../../molecules/Value'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'
import { useField } from 'react-final-form'

const SubscribedEmail = () => {
  const { input: subscribedEmail } = useField('subscribed_email')

  const handleChange = checked => {
    if (!checked) subscribedEmail.onChange(null)
  }

  return (
    <>
      <FieldCheckboxTitle
        name="has_subscribed_email"
        label="Asignar correo para recibir respuestas."
        description="Escribe el correo al que se notificará cada vez que el formulario reciba una respuesta."
        onChange={handleChange}
      />
      <Value name="has_subscribed_email">
        {({ value: hasSubscribedEmail }) =>
          hasSubscribedEmail && (
            <TextField
              name="subscribed_email"
              margin="none"
              placeholder="Ej.: nombre@micorreo.com"
              validate={validations.mix(validations.required(), validations.isEmail())}
              rootClassName="ml-7"
              ariaLabel="Dirección de correo para recibir respuestas"
            />
          )
        }
      </Value>
    </>
  )
}

export default SubscribedEmail
