import { isFirst, isLast } from '../../../utils/destroyableFields'
import useFieldValue from '../../useFieldValue'

export const useAleatory = () => {
  const aleatoryOrder = useFieldValue('aleatory_order')
  const defaultQuestions = useFieldValue('default_questions')
  const requireHideOptions = Boolean(defaultQuestions?.length) && aleatoryOrder

  const canStepChangeByAleatory = pageNumber => !(requireHideOptions && pageNumber === 1)

  const questionForceHide = (pageNumber, questions, index) => ({
    up: requireHideOptions && pageNumber === 2 && isFirst(questions, index),
    down: requireHideOptions && pageNumber === 1 && isLast(questions, index)
  })

  return { canStepChangeByAleatory, questionForceHide }
}
