import React from 'react'
import PropTypes from 'prop-types'
import FieldTitle from '../../../molecules/FieldTitle'
import { fieldTitle } from '../../../../configs/templatesConfig'
import clsx from 'clsx'
import PaymentMethodConfigurationSection from '../PaymentMethodConfigurationSection'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'

const name = 'it_costs'

const Payment = ({ config }) => {
  const hasPaymentMethodConfig = config.enabled_payment_methods?.has_transfer_accounts
  const { title, hint } = fieldTitle(name, config.it_costs)
  return (
    <div className={clsx('flex flex-col', !hasPaymentMethodConfig && 'md:flex-row')}>
      <div>
        {config.it_costs === 'official_decide' ? (
          <FieldCheckboxTitle name={name} label={title} description={hint} disabled={!hasPaymentMethodConfig} />
        ) : (
          <FieldTitle title={title} hint={hint} />
        )}
      </div>
      <PaymentMethodConfigurationSection hasPaymentMethodConfig={hasPaymentMethodConfig} config={config} />
    </div>
  )
}

export default Payment

Payment.propTypes = {
  config: PropTypes.object
}
