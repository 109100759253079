import React, { useState } from 'react'
import PropTypes from 'prop-types'

const AsyncFormErrorContext = React.createContext(null)

export function useAsyncFormErrorContext() {
  return React.useContext(AsyncFormErrorContext)
}

export function AsyncFormErrorProvider(props) {
  const [asyncError, setAsyncError] = useState({})

  return (
    <AsyncFormErrorContext.Provider value={{ setAsyncError, asyncError }}>
      {props.children}
    </AsyncFormErrorContext.Provider>
  )
}

AsyncFormErrorProvider.propTypes = {
  children: PropTypes.node
}
