import React from 'react'
import CheckboxField from '../../../../molecules/fields/CheckboxField'
import FieldTitle from '../../../../molecules/FieldTitle'
import InfoTooltip from '../../../../molecules/InfoTooltip'

const AleatoryOrder = () => {
  return (
    <>
      <FieldTitle title="Orden de preguntas y respuestas" className="md:mb-3" />
      <CheckboxField name="aleatory_order" margin="none" label="Presentar preguntas y respuestas de forma aleatoria." />
      <p className="ml-7 text-gray-500">
        Las preguntas y las opciones de respuesta se mostrarán de forma aleatoria para cada persona que complete el
        formulario. <br />
        <span className="font-medium">Revisa más información sobre esta funcionalidad.</span>
        <InfoTooltip
          iconClassName="text-black"
          leaveTouchDelay={10000}
          tooltipText="Las preguntas y las opciones de respuesta se mostrarán de forma aleatoria para cada persona que complete el formulario. Si estableces grupos de preguntas, estas se mantendrán en el mismo bloque. Ten en cuenta que las preguntas para recoger información personal (nombre y apellido, tipo y número de documento de identidad, correo electrónico, celular y ubigeo) no se verán afectadas por esta funcionalidad y se mostrarán en el orden que establezcas."
        />
      </p>
    </>
  )
}

export default AleatoryOrder

AleatoryOrder.propTypes = {}
