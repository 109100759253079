import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { PaymentMethods } from './PaymentMethods'

const PaymentSetup = ({ paymentMethods, showPaymentMothds = true }) => {
  return (
    <div className="ml-7 mt-2">
      <TextField
        leftDecorator={<span className="mr-4">S/ </span>}
        name="cost"
        size="md"
        label="Costo del trámite"
        validate={validations.required()}
        margin={showPaymentMothds ? 'normal' : 'none'}
      />
      {showPaymentMothds && (
        <Fragment>
          <p className="font-bold">Medios de pago</p>
          <p className="mb-4">Selecciona los medios de pago habilitados para este trámite:</p>
          <PaymentMethods
            paymentTransferAccounts={paymentMethods?.has_transfer_accounts}
            enabledPaymentTransferAccountsAttributes={paymentMethods?.payment_transfer_accounts_attributes}
          />
        </Fragment>
      )}
    </div>
  )
}
export default PaymentSetup

PaymentSetup.propTypes = {
  paymentMethods: PropTypes.exact({
    has_transfer_accounts: PropTypes.bool,
    payment_transfer_accounts_attributes: PropTypes.arrayOf(PropTypes.object)
  }),
  showPaymentMothds: PropTypes.bool
}
