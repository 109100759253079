import React from 'react'
import CheckboxField from '../../../../molecules/fields/CheckboxField'
import FieldTitle from '../../../../molecules/FieldTitle'
import useFieldValue from '../../../../../hooks/useFieldValue'
import TextField from '../../../../molecules/fields/TextField'
import * as validations from '../../../../../utils/validations'
import {
  addDefaultQuestion,
  removeDefaultQuestion,
  editDefaultOtherQuestion
} from '../../../../../configs/formConfigurationConfig'
import { useFieldArray } from 'react-final-form-arrays'
import { useDebouncedCallback } from '../../../../../hooks/useDebouncedCallback'

const DefaultQuestions = () => {
  const defaultQuestionsValue = useFieldValue('default_questions')
  const stepsAttributesValue = useFieldValue('steps_attributes')
  const { fields } = useFieldArray(`steps_attributes[0].questions_attributes`)

  const checkboxes = [
    { label: 'Nombres y apellidos', value: 'full_name_applicant' },
    { label: 'Tipo y número de documento de identidad', value: 'doc_id_type_applicant' },
    { label: 'Correo electrónico', value: 'email_applicant' },
    { label: 'Número de celular', value: 'phone_applicant' },
    { label: 'Ubigeo', value: 'ubigeo' },
    { label: 'Otro', value: 'other' }
  ]

  const handleChange = (checked, value) => {
    if (checked) {
      addDefaultQuestion({
        question: value,
        fields,
        steps: stepsAttributesValue,
        checkedValues: defaultQuestionsValue,
        addStep: defaultQuestionsValue.length === 0
      })
    } else {
      removeDefaultQuestion({
        question: value,
        steps: stepsAttributesValue,
        checkedValues: defaultQuestionsValue,
        deleteStep: defaultQuestionsValue.length === 1
      })
    }
  }

  const handleChangeOtherText = useDebouncedCallback(
    value => editDefaultOtherQuestion(stepsAttributesValue, value),
    300
  )

  return (
    <>
      <FieldTitle
        title="Datos de la persona evaluada"
        hint="Selecciona qué información deseas solicitar a la persona. Los datos que elijas aparecerán como preguntas predeterminadas en tu formulario."
      />
      {checkboxes.map(({ label, value }) => (
        <CheckboxField
          key={value}
          name="default_questions"
          label={label}
          value={value}
          margin="none"
          onChange={checked => handleChange(checked, value)}
        />
      ))}
      {Array.isArray(defaultQuestionsValue) && defaultQuestionsValue?.includes('other') && (
        <TextField
          name="default_questions_other_text"
          size="2xl"
          className="md:min-w-96 mt-2 ml-7"
          placeholder="Ingresa el dato que solicitarás"
          ariaLabel="Ingresa el dato que solicitarás"
          validate={validations.required()}
          onChange={handleChangeOtherText}
        />
      )}
    </>
  )
}

export default DefaultQuestions

DefaultQuestions.propTypes = {}
