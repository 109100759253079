import { Machine as machine, assign } from 'xstate'
import { serialize } from 'object-to-formdata'
import axios from 'axios'
import i18next from 'i18next'

const INITIAL_HASHID = 'myqehlw6'

const handleUploadProgress = (progressEvent, send) => {
  const filesUploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
  send({ type: 'INCREASE_FILES_UPLOAD_PERCENTAGE', filesUploadPercentage })
}

const saveAnswer = (form, citizenFormData, answerData, send) => {
  const language = i18next.language

  const formData = serialize(
    { ...citizenFormData, sisoli: 'sisoli', filling_start_time: form.filling_start_time },
    { indices: true }
  )
  if (form.isPreview) {
    return Promise.resolve({ hashid: INITIAL_HASHID, created_at_format: '16 jun 2021 11:12' })
  } else {
    const config = {
      onUploadProgress: progressEvent => {
        handleUploadProgress(progressEvent, send)
      },
      params: { locale: language }
    }
    if (answerData.hashid === INITIAL_HASHID) return createAnswer(form, formData, config)
    else return updateAnswer(answerData, formData, config)
  }
}
const createAnswer = (form, formData, config) =>
  axios.post(`/v1/forms/${form.id}/answers.json`, formData, config).then(res => res.data)

const updateAnswer = (answerData, formData, config) =>
  axios.put(`/v1/answers/${answerData.hashid}.json`, formData, config).then(res => res.data)

const setCitizenFormValues = assign({
  citizen_form_data: (ctx, event) => ({ ...ctx.citizen_form_data, ...event.values }),
  citizen_form_values: (ctx, event) => ({ ...ctx.citizen_form_values, ...event.submitValues })
})

const goToNextStep = assign({
  activeStep: ctx => ctx.activeStep + 1
})

const goToPrevStep = assign({
  activeStep: ctx => ctx.activeStep - 1
})

const goToStep = assign({
  activeStep: (ctx, event) => event.activeStep
})

const setFormContext = assign({
  form_data: (ctx, event) => event.form_data
})

const setIsLastStep = assign({
  isLastStep: ctx => ctx.activeStep === ctx.form_data.max_steps - 1
})

const toggleServerResponsePending = assign({
  server_response_pending: ctx => !ctx.server_response_pending
})

const increaseUploadPercentage = assign({
  filesUploadPercentage: (ctx, event) => event.filesUploadPercentage
})

const setAnswerData = assign({ answer_data: (_, event) => event.data })

const setServerResponseError = assign({
  server_response_error: (_, event) => event.data.response.data.error || event.data.response.data.response
})

export const citizenFormMachine = machine(
  {
    id: 'citizen_form',
    initial: 'init',
    context: {
      citizen_form_data: {},
      answer_data: { hashid: INITIAL_HASHID, created_at_format: '16 jun 2021 11:12' },
      server_response_error: null,
      server_response_pending: false,
      activeStep: 0,
      form_data: null,
      filesUploadPercentage: -1,
      isLastStep: false
    },
    states: {
      init: {
        on: {
          SET_INITIAL_CONTEXT_WITH_EXTERNAL_DATA: [
            {
              target: 'filling_form',
              actions: ['setFormContext', 'setIsLastStep']
            }
          ]
        }
      },
      filling_form: {
        on: {
          GO_BACK: {
            target: 'filling_form',
            actions: ['goToPrevStep', 'setIsLastStep']
          },
          GO_NEXT: {
            target: 'deciding_go_next_stage',
            actions: ['setCitizenFormValues']
          },
          GOTO_PREVIEW: {
            target: 'filling_form',
            actions: ['goToStep', 'setIsLastStep']
          },
          PAY_PREVIEW: {
            target: 'paying',
            actions: 'goToStep'
          }
        }
      },
      deciding_go_next_stage: {
        always: [
          {
            target: 'filling_form',
            cond: ctx => ctx.activeStep + 1 < ctx.form_data.steps_attributes.length,
            actions: ['goToNextStep', 'setIsLastStep']
          },
          {
            target: 'saving_answer',
            cond: ctx => ctx.activeStep + 1 >= ctx.form_data.steps_attributes.length
          }
        ]
      },
      saving_answer: {
        entry: 'toggleServerResponsePending',
        exit: 'toggleServerResponsePending',
        invoke: {
          id: 'submitForm',
          src: ctx => send => saveAnswer(ctx.form_data, ctx.citizen_form_data, ctx.answer_data, send),
          onDone: {
            target: 'after_save_answer',
            actions: 'setAnswerData'
          },
          onError: {
            target: 'filling_form',
            actions: 'setServerResponseError'
          }
        },
        on: {
          INCREASE_FILES_UPLOAD_PERCENTAGE: {
            actions: 'increaseUploadPercentage'
          }
        }
      },
      after_save_answer: {
        always: [
          {
            target: 'validating_email_or_phone',
            cond: ctx => ctx.answer_data.email_phone_applicant_response
          },
          {
            target: 'paying',
            cond: ctx => ctx.form_data.it_costs && !ctx.answer_data.email_phone_applicant_response,
            actions: ['goToNextStep', 'setIsLastStep']
          },
          {
            target: 'response',
            cond: ctx => !ctx.form_data.it_costs && !ctx.answer_data.email_phone_applicant_response
          }
        ]
      },
      validating_email_or_phone: {
        on: {
          GO_BACK: {
            target: 'filling_form'
          },
          GO_NEXT: {
            target: 'after_validating_email_or_phone'
          }
        }
      },
      after_validating_email_or_phone: {
        always: [
          {
            target: 'paying',
            cond: ctx => ctx.form_data.it_costs,
            actions: ['goToNextStep', 'setIsLastStep']
          },
          {
            target: 'response',
            cond: ctx => !ctx.form_data.it_costs
          }
        ]
      },
      paying: {
        on: {
          GO_BACK: {
            target: 'filling_form',
            actions: ['goToPrevStep', 'setIsLastStep']
          },
          GO_NEXT: {
            target: 'response'
          },
          GOTO_PREVIEW: {
            target: 'filling_form',
            actions: 'goToStep'
          }
        }
      },
      response: {}
    }
  },
  {
    actions: {
      setCitizenFormValues,
      goToNextStep,
      goToPrevStep,
      goToStep,
      setFormContext,
      setAnswerData,
      setServerResponseError,
      toggleServerResponsePending,
      increaseUploadPercentage,
      setIsLastStep
    }
  }
)
