import React, { Fragment, useState } from 'react'
import axios from 'axios'
import UsersTable from './UsersTable'
import { useQueryClient } from 'react-query'
import useFilters from '../../../hooks/useFilters'
import { BoxTable } from '../../molecules/BoxTable'
import UsersFilter from './UsersFilter'
import useUserPermission from '../../../hooks/useUserPermission'
import DialogErrorMessage from './DialogErrorMessage'
import PropTypes from 'prop-types'

const sortOptions = [
  {
    value: 'name asc',
    label: 'Nombre (A-Z)'
  },
  {
    value: 'name desc',
    label: 'Nombre (Z-A)'
  },
  {
    value: 'created_at desc',
    label: 'Más recientes primero'
  },
  {
    value: 'created_at asc',
    label: 'Más antiguos primero'
  }
]

export const Users = ({ paramsLinkBranchOffice }) => {
  const [paramsDeleteDialog, setParamsDeleteDialog] = useState()
  const urlFetch = `/admin/v1/users.json`
  const { canCreate } = useUserPermission('user')

  const {
    onFilter,
    initialFilters,
    results: users
  } = useFilters(urlFetch, { user_sort: 'name asc' }, { pageName: 'user_page' })
  const queryClient = useQueryClient()
  const handleDestroy = async user => {
    if (window.confirm(`¿Estás seguro que deseas eliminar el usuario ${user.name}?`)) {
      try {
        await axios.delete(`/admin/v1/users/${user.id}.json`)
        queryClient.invalidateQueries(urlFetch)
      } catch (error) {
        if (error.response.data.dialog) {
          setParamsDeleteDialog(error.response.data.dialog[0])
        } else {
          window.alert(error.response.data.message)
        }
      }
    }
  }

  return (
    <Fragment>
      <DialogErrorMessage onClose={() => setParamsDeleteDialog()} params={paramsDeleteDialog} />
      <BoxTable
        id="usuarios"
        initialFilters={initialFilters}
        onFilter={onFilter}
        buttons={
          canCreate && [
            {
              name: '+ Crear usuario',
              link: `/usuarios/validar?${paramsLinkBranchOffice}`,
              variant: 'secondary',
              className: 'md:w-56'
            }
          ]
        }
        filters={<UsersFilter />}
        values={users}
        paginationLabel="user_page"
        searchFieldName="users_search"
        displayTotal
        displaySort
        sortByName="user_sort"
        sortOptions={sortOptions}
        searchPlaceholder="Buscar por nombre o DNI"
      >
        <UsersTable users={users.data} onDestroy={handleDestroy} />
      </BoxTable>
    </Fragment>
  )
}

Users.propTypes = {
  paramsLinkBranchOffice: PropTypes.string
}
