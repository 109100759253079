import React from 'react'
import Value from '../../../molecules/Value'
import PublishScheduleRangeField from '../../../molecules/fields/PublishScheduleRangeField'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'

const name = 'has_published_schedule'
const PublishSchedule = () => {
  return (
    <div>
      <FieldCheckboxTitle
        name={name}
        label="Publicación y despublicación del formulario"
        description="El formulario se publicará y/o despublicará en la fecha y hora seleccionada."
      />
      <Value name="has_published_schedule">
        {({ value: hasScheduleValue }) => hasScheduleValue && <PublishScheduleRangeField />}
      </Value>
    </div>
  )
}

export default PublishSchedule
