import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Form, Field } from 'react-final-form'
import { Button } from '../../atoms/Button'
import CheckboxField from '../../molecules/fields/CheckboxField'
import * as validations from '../../../utils/validations'
import FormHelperText from '@material-ui/core/FormHelperText'
import { focusOnFirstError } from '../../../utils/decorators'
import { PaymentAmountTag } from '../../molecules/PaymentAmountTag'
import { LinearProgressBar } from '../../atoms/LinearProgressBar'
import { clearMutator } from '../../../utils/formMutators'
import arrayMutators from 'final-form-arrays'
import FormStepHeader from './FormStepHeader'
import QuestionsAndSubQuestions from '../question_fields/QuestionsAndSubQuestions'
import ScrollToTop from '../../molecules/ScrollToTop'
import { ScrollToElement } from '../../molecules/ScrollToElement'
import { usePrevious } from '../../../hooks/usePrevious'
import { flatAnswers } from '../../../utils/helpers'
import { swornDeclarationUrl } from '../../../configs/assetsConfig'
import { useTranslation } from 'react-i18next'
import useTextValidation from '../../../hooks/useTextValidation'
import { useAsyncFormErrorContext } from '../../../context/AsyncFormErrorProvider'

const noNeedSwormDeclarationFormsIds = [2238]

const handleSubmit = (values, send, asyncError) => {
  if (Object.keys(asyncError).length === 0) {
    const flattedAnswers = { ...values, answers: flatAnswers(values.answers) }
    send('GO_NEXT', { values: flattedAnswers, submitValues: values })
  }
}

const CitizenForm = ({ data, state, send, isPreview }) => {
  const { t } = useTranslation(['general', 'button'])
  const translationText = useTextValidation()
  const { asyncError } = useAsyncFormErrorContext()
  const {
    privacy_policy: privacyPolicy,
    sworn_declaration: swornDeclaration,
    it_costs: itCosts,
    hint,
    cost,
    max_steps: maxSteps,
    should_validate_email_or_phone: shouldValidateEmailOrPhone,
    procedure_management_id: procedureManagementId
  } = data
  const step = data.steps_attributes[state.context.activeStep]
  const questions = step?.questions_attributes || []
  const hasAtLeastOneFileQuestion = questions.reduce((acc, curr) => acc || curr.kind === 'file', false)
  const isLastFormStep = state.context.activeStep + 1 === data.steps_attributes.length

  const prevActiveStep = usePrevious(state.context.activeStep)
  const firstRender = prevActiveStep === undefined
  const pageWasChanged = state.context.activeStep !== prevActiveStep
  const scrollToElement = !firstRender && pageWasChanged

  return (
    <Fragment>
      {itCosts ? (
        <div className="text-blue-700 font-medium bg-blue-300 p-4 mb-8">
          <div className="hidden md:flex justify-center items-center">
            Este trámite tiene un costo de <PaymentAmountTag amount={cost} className="md:mx-2" />, que deberás pagar en
            el
            <strong className="ml-1">paso {maxSteps}.</strong>
          </div>
          <div className="md:hidden">
            <PaymentAmountTag amount={cost} className="md:mx-2" />
            <div className="mt-2">
              Este es el costo del trámite que deberás pagar en el <strong>paso {maxSteps}.</strong>
            </div>
          </div>
        </div>
      ) : null}
      <div className="hint-html-injected mb-8 md:mx-0 mx-5" dangerouslySetInnerHTML={{ __html: hint }} />
      <FormStepHeader state={state} form={data} name={step?.name} />
      <Form
        subscription={{ submitting: true, pristine: true, modifiedSinceLastSubmit: true, errors: true }}
        onSubmit={v => handleSubmit(v, send, asyncError)}
        autoComplete="off"
        initialValues={state.context.citizen_form_values}
        keepDirtyOnReinitialize={true}
        decorators={[focusOnFirstError]}
        mutators={{
          ...arrayMutators,
          clear: clearMutator
        }}
      >
        {({ handleSubmit, modifiedSinceLastSubmit, errors }) => {
          return (
            <form className="p-5 md:p-8 bg-neutral-200 md:rounded" onSubmit={handleSubmit}>
              <Field component="input" className="hidden" name="form_version" initialValue={data.version_schema} />
              <QuestionsAndSubQuestions
                questions={questions}
                stepsAttributes={data.steps_attributes}
                activeStep={state.context.activeStep}
                procedureManagementId={procedureManagementId}
              />
              {privacyPolicy && isLastFormStep && !noNeedSwormDeclarationFormsIds.includes(data.id) && (
                <CheckboxField
                  name="accepts_privacy_policy"
                  validate={validations.required(translationText.required)}
                  margin="dense"
                  className="-mt-4"
                  label={
                    <p>
                      {t('text_accept')}{' '}
                      <a href={privacyPolicy.url} target="_blank" rel="noreferrer">
                        {t('text_privacy_policy')}
                      </a>
                    </p>
                  }
                />
              )}
              {swornDeclaration && isLastFormStep && (
                <CheckboxField
                  className="mb-4"
                  name="accepts_sworn_declaration"
                  validate={validations.required(translationText.required)}
                  label={
                    <p>
                      Acepto{' '}
                      <a href={swornDeclarationUrl} target="_blank" rel="noreferrer">
                        la declaración jurada de veracidad de la información
                      </a>
                    </p>
                  }
                />
              )}
              {!modifiedSinceLastSubmit && Object.keys(errors).length === 0 && state.context.server_response_error && (
                <div className="relative">
                  <FormHelperText className="my-4" error>
                    {state.context.server_response_error}
                  </FormHelperText>
                </div>
              )}
              <div className="flex flex-col-reverse md:flex-row justify-end">
                {state.context.activeStep > 0 && (
                  <div className="md:w-1/3 lg:w-1/4 md:mr-3">
                    <Button onClick={() => send('GO_BACK')} variant="secondary" className="" size="full">
                      {t('button_back', { ns: 'button' })}
                    </Button>
                  </div>
                )}
                <div className="md:w-48 mb-3 md:mb-0 h-13">
                  <Button disabled={state.context.server_response_pending} size="full" type="submit">
                    {state.context.isLastStep && !shouldValidateEmailOrPhone
                      ? t('button_send', { ns: 'button' })
                      : t('button_continue', { ns: 'button' })}
                  </Button>
                  {hasAtLeastOneFileQuestion && <LinearProgressBar percentage={state.context.filesUploadPercentage} />}
                </div>
              </div>
              {isPreview && scrollToElement ? (
                <ScrollToElement trigger={state.context.activeStep} elementId="preview-scroll-to" />
              ) : (
                <ScrollToTop trigger={state.context.activeStep} />
              )}
            </form>
          )
        }}
      </Form>
    </Fragment>
  )
}

export default CitizenForm

CitizenForm.propTypes = {
  data: PropTypes.object,
  isPreview: PropTypes.bool,
  send: PropTypes.func,
  state: PropTypes.object
}
