import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { useFieldArray } from 'react-final-form-arrays'
import QuestionsFields from './QuestionsFields'
import { Button } from '../../atoms/Button'
import { ReactSVG as Svg } from 'react-svg'
import pageJoinIcon from '../../../images/icons/page_join.svg'
import StepHeader from './StepHeader'
import AcceptCloseModal from '../../molecules/dialogs/AcceptCloseModal'
import { getActives, getIndexToShow, isFirst } from '../../../utils/destroyableFields'
import StepsMove from './StepsMove'
import Error from '../../molecules/Error'
import formValidations from '../../../utils/formValidations'
import * as validations from '../../../utils/validations'
import { FormTypeContext } from '../../../utils/contexts'
import { useAleatory } from '../../../hooks/feature/form/useAleatory'

const StepsFields = ({
  addQuestions = true,
  addPages = true,
  sortQuestions = true,
  editableAlternatives = false,
  editDeleteQuestions = true
}) => {
  const [indexToRemove, setIndexToRemove] = useState(null)
  const [fieldToMove, setFieldToMove] = useState(null)
  const typeForm = useContext(FormTypeContext)
  const { canStepChangeByAleatory } = useAleatory()
  const { fields } = useFieldArray('steps_attributes', {
    validate: validations.mix(
      formValidations.validateFirstEmail,
      formValidations.validateApplicantQuestionsInProcedureManagement(typeForm)
    )
  })
  return (
    <>
      {fields.map(
        (name, index) =>
          !fields.value[index]?._destroy && (
            <div key={index} data-testid={`pagina-${index}`}>
              <div className="md:w-2/12 lg:w-1/12 md:ml-auto md:pl-4">
                {!isFirst(fields.value, index) &&
                  addPages &&
                  canStepChangeByAleatory(getIndexToShow(fields.value, index) - 1) && (
                    <Button
                      variant="text"
                      className="text-sm text-purple-700 leading-4 w-full md:max-w-24 bg-neutral-200 md:bg-neutral-100 mt-8 md:mt-0"
                      classes={{ label: 'text-left' }}
                      size="none"
                      onClick={() => {
                        fields.joinPage(index, 'up')
                        fields.markForDestroy(index)
                      }}
                      startIcon={<Svg src={pageJoinIcon} />}
                    >
                      Unir páginas
                    </Button>
                  )}
              </div>
              {getActives(fields.value).length > 1 && (
                <StepHeader
                  baseName={name}
                  pageNumber={getIndexToShow(fields.value, index)}
                  onSwap={() => setFieldToMove(fields.value)}
                  onDelete={() => setIndexToRemove(index)}
                  step={fields.value[index]}
                />
              )}
              <QuestionsFields
                name={`${name}.questions_attributes`}
                editableAlternatives={editableAlternatives}
                canDo={{ addQuestions, addPages, sortQuestions, editDeleteQuestions }}
                stepIndex={index}
                validate={validations.requiredArray('Configura por lo menos una pregunta', 1)}
              />
            </div>
          )
      )}
      <Error name={fields.name} className="relative js-field-container" />
      <AcceptCloseModal
        title="Al borrar la página, eliminarás todas sus preguntas, ¿seguro que deseas borrarla?"
        buttonLabel="Borrar página"
        onClose={() => setIndexToRemove(null)}
        onAccept={() => {
          fields.markForDestroy(indexToRemove)
          setIndexToRemove(null)
        }}
        open={indexToRemove !== null}
      >
        Si quieres juntar preguntas en una página utiliza la opción de
        <strong className="ml-1"> Unir páginas</strong>.
      </AcceptCloseModal>
      <StepsMove fieldToMove={fieldToMove} onClose={() => setFieldToMove(null)} open={fieldToMove !== null} />
    </>
  )
}
export default StepsFields

StepsFields.propTypes = {
  addPages: PropTypes.bool,
  addQuestions: PropTypes.bool,
  editableAlternatives: PropTypes.bool,
  sortQuestions: PropTypes.bool,
  editDeleteQuestions: PropTypes.bool
}
