import React from 'react'
import useToggle from '../../../hooks/useToggle'
import { Button } from '../../atoms/Button'
import CategoriesNew from './CategoriesNew'
import AddIcon from '@material-ui/icons/Add'

const CreateCategoryOnce = () => {
  const [showCreateForm, setShowCreateForm] = useToggle(false)

  return (
    <>
      {showCreateForm ? (
        <CategoriesNew onFormSubmit={setShowCreateForm} onCancel={setShowCreateForm} />
      ) : (
        <div className="text-center">
          <h4 className="font-bold text-xl mb-5">Aún no has creado ninguna categoría</h4>
          <Button className="max-w-72" size="s" variant="secondary" onClick={setShowCreateForm}>
            <AddIcon />
            Crear categoría
          </Button>
        </div>
      )}
    </>
  )
}
export default CreateCategoryOnce
