import PropTypes from 'prop-types'
import React from 'react'
import DocIdTypeCitizenField from '../question_fields/DocIdTypeCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin } = props
  return (
    <DocIdTypeCitizenField
      index={index}
      {...question}
      name={name}
      disabled={disabled}
      margin={margin}
      uniqueResponseQuestion={question.unique_response_question}
    />
  )
}

const DocTypeQuestion = {
  Citizen,
  name: 'DocTypeQuestion'
}

export default DocTypeQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}
