import * as uuid from 'uuid'
import clonedeep from 'lodash.clonedeep'

const defaultQuestionsList = {
  full_name_applicant: {
    kind: 'full_name_applicant',
    payload: {
      label: 'Nombres y apellidos del solicitante',
      name_label: 'Nombres',
      father_last_name_label: 'Apellido paterno',
      mother_last_name_label: 'Apellido materno',
      default_question: true
    },
    required: true
  },
  email_applicant: {
    kind: 'email_phone_applicant',
    payload: {
      contact_medium: 'email',
      label: 'Correo electrónico de contacto (debes tener acceso al correo)',
      default_question: true
    },
    required: true
  },
  phone_applicant: {
    kind: 'email_phone_applicant',
    payload: {
      contact_medium: 'cellphone',
      label: 'Teléfono celular de contacto (debes tener acceso al teléfono)',
      default_question: true
    },
    required: true
  },
  phone: {
    kind: 'phone',
    payload: {
      contact_medium: 'cellphone',
      label: 'Teléfono celular de contacto',
      default_question: true
    },
    required: true
  },
  doc_id_type_applicant: {
    kind: 'doc_id_type_applicant',
    payload: {
      doc_type_label: 'Tipo de documento de identidad del solicitante',
      doc_id_label: 'Número de documento de identidad del solicitante',
      person_type: 'natural-juridica',
      default_question: true
    },
    required: true
  },
  ubigeo: {
    kind: 'ubigeo',
    payload: {
      label: 'Ubigeo',
      ubigeo_region_label: 'Departamento',
      ubigeo_city_label: 'Provincia',
      ubigeo_district_label: 'Distrito',
      default_question: true
    },
    required: true
  },
  other: {
    kind: 'short_text',
    payload: {
      label: '',
      default_question: true
    },
    required: true
  }
}

const questionNamesByKind = hasEmailQuestion => ({
  other: 'short_text',
  ubigeo: 'ubigeo',
  doc_id_type_applicant: 'doc_id_type_applicant',
  full_name_applicant: 'full_name_applicant',
  email_applicant: 'email_phone_applicant',
  phone_applicant: hasEmailQuestion ? 'phone' : 'email_phone_applicant'
})

const addDefaultQuestion = ({ question, fields, steps, checkedValues, addStep }) => {
  if (addStep) {
    const newQuestionAttributes = []
    addDefaultQuestionToFields(newQuestionAttributes, defaultQuestionsList[question])
    steps.splice(0, 0, { questions_attributes: newQuestionAttributes })
  } else {
    if (question === 'email_applicant') {
      if (checkedValues.includes('phone_applicant')) {
        transformPhoneApplicantToPhone(steps)
      }
      addDefaultQuestionToFields(fields, defaultQuestionsList[question])
    } else if (question === 'phone_applicant') {
      if (checkedValues.includes('email_applicant')) {
        addDefaultQuestionToFields(fields, defaultQuestionsList.phone)
      } else {
        addDefaultQuestionToFields(fields, defaultQuestionsList.phone_applicant)
      }
    } else {
      addDefaultQuestionToFields(fields, defaultQuestionsList[question])
    }
  }
}

const addDefaultQuestionToFields = (fields, question) => {
  fields.push({ ...clonedeep(question), key: uuid.v4() })
}

const transformPhoneApplicantToPhone = steps => {
  const cellphoneQuestion = getElementByKindInSteps(steps, 'email_phone_applicant')
  if (cellphoneQuestion) {
    cellphoneQuestion.kind = defaultQuestionsList.phone.kind
    cellphoneQuestion.payload.label = defaultQuestionsList.phone.payload.label
  }
}

const transformPhoneToPhoneApplicant = steps => {
  const cellphoneQuestion = getElementByKindInSteps(steps, 'phone')
  if (cellphoneQuestion) {
    cellphoneQuestion.kind = defaultQuestionsList.phone_applicant.kind
    cellphoneQuestion.payload.label = defaultQuestionsList.phone_applicant.payload.label
  }
}

const getElementByKindInSteps = (steps, kind) => {
  const step = steps[0]
  const selectedQuestion = step.questions_attributes.find(
    question =>
      question.kind === kind && !question._destroy && question?.payload?.default_question?.toString() === 'true'
  )
  if (selectedQuestion) {
    return selectedQuestion
  }
  return null
}

const removeDefaultQuestion = ({ question, steps = [], checkedValues, deleteStep }) => {
  if (deleteStep) {
    steps[0]._destroy = '1'
  } else {
    const hasEmailQuestion = checkedValues.includes('email_applicant')
    const questionToDelete = getElementByKindInSteps(steps, questionNamesByKind(hasEmailQuestion)[question])
    if (questionToDelete) {
      if (question === 'email_applicant' && checkedValues.includes('phone_applicant')) {
        transformPhoneToPhoneApplicant(steps)
      }
      questionToDelete._destroy = '1'
    }
  }
}

const editDefaultOtherQuestion = (steps, value) => {
  const otherQuestion = getElementByKindInSteps(steps, 'short_text')
  if (otherQuestion) {
    otherQuestion.payload.label = value
  }
}

export { addDefaultQuestion, removeDefaultQuestion, editDefaultOtherQuestion }
