import PropTypes from 'prop-types'
import React from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import IconButton from '@material-ui/core/IconButton'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import DeleteIcon from '@material-ui/icons/Delete'
import { createdFromTemplate } from '../../../configs/questionTypesAdminConfig'
import Tooltip from '../../atoms/Tooltip'
import StepManualEntryName from '../question_config_fields/StepManualEntryName'
import { useAleatory } from '../../../hooks/feature/form/useAleatory'

const StepHeader = ({ baseName, pageNumber, onSwap, onDelete, step }) => {
  const { canStepChangeByAleatory } = useAleatory()
  const canStepChange = canStepChangeByAleatory(pageNumber)

  return (
    <div className="flex flex-col mb-8 mt-8">
      {createdFromTemplate(step) ? (
        <>
          <div className="justify-between items-center text-white font-bold bg-neutral-500 p-4">
            <div className="text-xl md:text-2xl">Página {pageNumber}</div>
            <div className="text-2xl md:text-3xl">{step.name}</div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center text-white font-bold text-3xl bg-neutral-500 p-4">
            Página {pageNumber}
            {canStepChange && (
              <div>
                <Tooltip title="Mover página">
                  <IconButton type="button" color="inherit" onClick={onSwap} aria-label="Mover página">
                    <ImportExportIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Borrar página">
                  <IconButton type="button" color="inherit" onClick={onDelete} aria-label="Borrar página">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="bg-neutral-300 p-6">
            <TextField
              name={`${baseName}.name`}
              label="Nombre de la página"
              margin="none"
              size="full"
              validate={validations.required()}
            />
            <StepManualEntryName baseName={baseName} margin="none" size="full" />
          </div>
        </>
      )}
    </div>
  )
}
export default StepHeader

StepHeader.propTypes = {
  baseName: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onSwap: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  step: PropTypes.shape({
    name: PropTypes.string
  }).isRequired
}
