import IconNot1 from '../images/icons/icon_not_1.svg'
import IconNot2 from '../images/icons/icon_not_2.svg'
import IconNot3 from '../images/icons/icon_not_3.svg'
import IconNot4 from '../images/icons/icon_not_4.svg'
import IconNot5 from '../images/icons/icon_not_5.svg'
import IconNot6 from '../images/icons/icon_not_6.svg'
import IconNot7 from '../images/icons/icon_not_7.svg'
import { roles } from './usersConfig'

const options = {
  validation: [
    { label: 'Inmediatamente', value: 'send' },
    { label: 'Programar fecha y hora', value: 'scheduled' }
  ],
  checkboxPermissions: roles.slice().reverse()
}

const icons = [
  { iconSvg: IconNot1, value: 'dispositivos' },
  { iconSvg: IconNot2, value: 'nuevo' },
  { iconSvg: IconNot3, value: 'idea' },
  { iconSvg: IconNot4, value: 'archivo' },
  { iconSvg: IconNot5, value: 'carpeta' },
  { iconSvg: IconNot6, value: 'jerarquia' },
  { iconSvg: IconNot7, value: 'general' }
]

const sortOptions = [
  {
    value: 'created_at desc',
    label: 'Más recientes primero'
  },
  {
    value: 'created_at asc',
    label: 'Más antiguos primero'
  }
]
const headerInfo = {
  title: 'Crear notificación',
  hint: 'Configura y define el contenido de la notificación que deseas crear.'
}

const headerInfoEdit = {
  title: 'Editar notificación',
  hint: 'Configura y define el contenido de la notificación que deseas editar.'
}
const getIcon = icon => icons.find(i => i.value === icon)?.iconSvg

export { options, icons, sortOptions, headerInfo, headerInfoEdit, getIcon }
