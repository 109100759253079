import PropTypes from 'prop-types'
import React, { useState } from 'react'
import AcceptCloseModal from '../../molecules/dialogs/AcceptCloseModal'
import { useForm } from 'react-final-form'
import UpDownButtons from './UpDownButtons'
import { getSubtype, isSubtitleFromTemplate } from '../../../configs/questionTypesAdminConfig'

const getShowIndexStep = (directionToJoin, step) =>
  directionToJoin === 'up' ? step.indexToShow - 1 : step.indexToShow + 1

const UpDownQuestions = ({ fields, index, step, forceHide }) => {
  const { mutators } = useForm()
  const [directionToJoin, setDirectionToJoin] = useState(null)
  const questionSubType = getSubtype(fields.value[index]?.kind).toLowerCase()
  const element = !isSubtitleFromTemplate(fields.value[index]) ? questionSubType : null
  const { up: forceHideUp, down: forceHideDown } = forceHide
  return (
    <>
      <UpDownButtons
        step={step}
        fields={fields}
        index={index}
        setDirectionToJoin={setDirectionToJoin}
        element={element}
        forceHideDown={forceHideDown}
        forceHideUp={forceHideUp}
      />
      <AcceptCloseModal
        title={`Estás moviendo la única pregunta de la Página ${step.indexToShow}`}
        buttonLabel="Unir páginas"
        onClose={() => setDirectionToJoin(null)}
        onAccept={() => {
          mutators.joinPage('steps_attributes', step.index, directionToJoin)
          mutators.markForDestroy('steps_attributes', step.index)
          setDirectionToJoin(null)
        }}
        open={directionToJoin !== null}
      >
        Al hacer esto, la página {step.indexToShow} se unirá a la página {getShowIndexStep(directionToJoin, step)}
      </AcceptCloseModal>
    </>
  )
}
export default UpDownQuestions

UpDownQuestions.propTypes = {
  fields: PropTypes.object,
  index: PropTypes.number,
  step: PropTypes.object,
  forceHide: PropTypes.object
}
