import PropTypes from 'prop-types'
import React from 'react'
import CheckboxField from './fields/CheckboxField'

const FieldCheckboxTitle = ({ name, label, description, onChange, disabled }) => {
  return (
    <>
      <CheckboxField
        name={name}
        label={label}
        margin="none"
        labelClassName="font-bold"
        onChange={onChange}
        disabled={disabled}
      />
      {description && <p className="ml-7 mb-2">{description}</p>}
    </>
  )
}
export default FieldCheckboxTitle

FieldCheckboxTitle.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
