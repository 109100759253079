import PropTypes from 'prop-types'
import React from 'react'
import { Button } from '../../atoms/Button'
import { ReactSVG as Svg } from 'react-svg'
import arrowCircleDown from '../../../images/icons/arrow_circle_down.svg'
import arrowCircleUp from '../../../images/icons/arrow_circle_up.svg'
import { getActives, getNextActiveIndex, getPrevActiveIndex, isFirst, isLast } from '../../../utils/destroyableFields'

const UpDownButtons = ({ fields, index, step, setDirectionToJoin, element, forceHideUp, forceHideDown }) => {
  const questions = fields.value
  const onlyOne = (step ? step.isFirst && step.isLast : true) && questions.length === 1
  const showUp = onlyOne || (step ? !step.isFirst : false) || !isFirst(fields.value, index)
  const showDown = onlyOne || (step ? !step.isLast : false) || !isLast(fields.value, index)
  const upText = `Subir ${element}`
  const downText = `Bajar ${element}`
  return (
    <>
      <div className="md:w-2/12 lg:w-1/12 flex md:flex-col justify-end md:justify-center md:pl-4 mt-2 md:mt-0">
        {showUp && !forceHideUp && (
          <Button
            className="text-left text-sm leading-5"
            classes={{ label: 'flex md:block', root: 'w-1/2 md:w-full' }}
            size="full"
            variant="text"
            disabled={onlyOne}
            aria-label={upText}
            onClick={() => {
              if (getActives(questions).length === 1) setDirectionToJoin && setDirectionToJoin('up')
              else fields.swap(index, getPrevActiveIndex(questions, index), 'up')
            }}
          >
            <Svg src={arrowCircleUp} className="md:mb-2 mr-2" />
            {element ? upText : null}
          </Button>
        )}
        {showDown && !forceHideDown && (
          <Button
            className="text-left text-sm leading-5"
            classes={{ label: 'flex flex-row-reverse md:block', root: 'w-1/2 md:w-full' }}
            size="full"
            variant="text"
            disabled={onlyOne}
            aria-label={downText}
            onClick={() => {
              if (getActives(questions).length === 1) setDirectionToJoin && setDirectionToJoin('down')
              else fields.swap(index, getNextActiveIndex(questions, index), 'down')
            }}
          >
            {element ? downText : null}
            <Svg src={arrowCircleDown} className="md:mt-2 mr-2" />
          </Button>
        )}
      </div>
    </>
  )
}
export default UpDownButtons

UpDownButtons.propTypes = {
  element: PropTypes.string,
  fields: PropTypes.object,
  index: PropTypes.number,
  setDirectionToJoin: PropTypes.func,
  step: PropTypes.object,
  forceHideUp: PropTypes.bool,
  forceHideDown: PropTypes.bool
}
