import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import IconButton from '../../molecules/buttons/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { AlertContext } from '../../../utils/contexts'

const FormsCopyButton = ({ form }) => {
  const { form: subscribedForm, isSubscriptionEnabled, setIsSubscriptionEnabled, setForm } = useContext(AlertContext)

  const handleClick = () => {
    setForm(form)
    setIsSubscriptionEnabled()
  }

  return (
    <IconButton
      text="Crear copia"
      onClick={handleClick}
      disabled={isSubscriptionEnabled && subscribedForm.id === form.id}
    >
      <FileCopyIcon />
    </IconButton>
  )
}

export default FormsCopyButton

FormsCopyButton.propTypes = {
  form: PropTypes.object.isRequired
}
