import React from 'react'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'

const name = 'unique_response_per_document'
const UniqueResponsePerDocument = () => {
  return (
    <div>
      <FieldCheckboxTitle
        name={name}
        label="Limitar a una respuesta por documento de identidad"
        description="El formulario permitirá solo una respuesta por documento de identidad."
      />
    </div>
  )
}

export default UniqueResponsePerDocument
