import { roles } from './usersConfig'

const options = {
  checkboxPermissions: roles.slice().reverse()
}

const frequencyOptions = [
  { name: 'Única', id: 'unique' },
  { name: 'Diaria', id: 'daily' },
  { name: 'En cada logueo', id: 'when_login' }
]

const getFrequencyOptionName = id => {
  return frequencyOptions.find(option => option.id === id).name
}

const headerInfo = {
  title: 'Crear aviso',
  hint: 'Configura y define el contenido del aviso que deseas crear.',
  submitButton: 'Crear aviso'
}

const headerInfoEdit = {
  title: 'Editar aviso',
  hint: 'Configura y define el contenido del aviso que deseas editar.',
  submitButton: 'Guardar cambios'
}

export { options, getFrequencyOptionName, frequencyOptions, headerInfo, headerInfoEdit }
