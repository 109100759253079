import React from 'react'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import CategoriesTable from '../../../organisms/onboarding/CategoriesTable'
import CreateCategoryOnce from '../../../organisms/onboarding/CreateCategoryOnce'
import useCategory from '../../../../hooks/useCategory'

const Categories = () => {
  const { getCategories } = useCategory()
  const { data: categories } = getCategories()

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <h1 aria-describedby="page-description" className="text-4xl font-bold title mb-2 mx-4 md:mx-0 md:mt-6">
          Categorias
        </h1>
        <p className="mb-8 mx-4 md:mx-0" id="page-description">
          Crea, edita y ordena las categorías. El orden que determines se verá reflejado en la sección &quot;Centro de
          ayuda&quot; de los usuarios de Facilita.
        </p>
        <div className="p-4 mx-4 md:mx-0 md:p-10 bg-neutral-100">
          {categories?.length === 0 ? <CreateCategoryOnce /> : <CategoriesTable categories={categories} />}
        </div>
      </Container>
    </>
  )
}
export default Categories
