import React from 'react'
import PropTypes from 'prop-types'
import CheckboxField from '../../../molecules/fields/CheckboxField'
import FieldTitle from '../../../molecules/FieldTitle'
import { swornDeclarationUrl } from '../../../../configs/assetsConfig'
import { fieldTitle } from '../../../../configs/templatesConfig'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'

const name = 'sworn_declaration'

const SwornDeclaration = ({ config }) => {
  const { title, hint } = fieldTitle(name, config.sworn_declaration)
  return (
    <>
      {config.sworn_declaration === 'official_decide' ? (
        <FieldCheckboxTitle name={name} label={title} description={hint} />
      ) : (
        <FieldTitle title={title} hint={hint} />
      )}
      {config.sworn_declaration === 'yes' && (
        <div className="mt-6 bg-neutral-300 p-4">
          <CheckboxField
            name="accepts_sworn_declaration"
            margin="none"
            label={
              <p>
                Acepto{' '}
                <a href={swornDeclarationUrl} target="_blank" rel="noreferrer">
                  la declaración jurada de veracidad de la información
                </a>
              </p>
            }
            disabled
          />
        </div>
      )}
    </>
  )
}

export default SwornDeclaration

SwornDeclaration.propTypes = {
  config: PropTypes.object
}
