import PropTypes from 'prop-types'
import React from 'react'
import { ReactSVG as Svg } from 'react-svg'
import arrowCircleDown from '../../../images/icons/arrow_circle_down.svg'
import arrowCircleUp from '../../../images/icons/arrow_circle_up.svg'
import { getNextActiveIndex, getPrevActiveIndex } from '../../../utils/destroyableFields'
import { IconButton } from '@material-ui/core'
import Tooltip from '../../atoms/Tooltip'
import { useAleatory } from '../../../hooks/feature/form/useAleatory'

const UpDownSteps = ({ steps, index, setSteps }) => {
  const prevIndex = getPrevActiveIndex(steps, index)
  const nextIndex = getNextActiveIndex(steps, index)
  const { canStepChangeByAleatory } = useAleatory()
  const handleSwapPage = (fromIndex, toIndex) => {
    const newSteps = [...steps]
    newSteps[fromIndex] = steps[toIndex]
    newSteps[toIndex] = steps[fromIndex]
    setSteps(newSteps)
  }
  return (
    <div className="flex flex-col justify-center pl-2">
      {prevIndex !== undefined && canStepChangeByAleatory(prevIndex + 1) && (
        <Tooltip title="Subir página">
          <IconButton
            classes={{ root: 'p-1' }}
            className="text-blue-700"
            onClick={() => handleSwapPage(index, prevIndex)}
            aria-label="Subir página"
          >
            <Svg src={arrowCircleUp} />
          </IconButton>
        </Tooltip>
      )}
      {nextIndex !== undefined && canStepChangeByAleatory(nextIndex) && (
        <Tooltip title="Bajar página">
          <IconButton
            classes={{ root: 'p-1' }}
            className="text-blue-700"
            onClick={() => handleSwapPage(index, nextIndex)}
            aria-label="Bajar página"
          >
            <Svg src={arrowCircleDown} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}
export default UpDownSteps

UpDownSteps.propTypes = {
  index: PropTypes.number,
  setSteps: PropTypes.func,
  steps: PropTypes.array
}
