import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { CitizenFormPageContainer } from './CitizenFormPageContainer'
import CitizenForm from './CitizenForm'
import { PaymentMethodFormSelectionCitizen } from '../payments/PaymentMethodFormSelectionCitizen'
import { FormServerResponse } from './FormServerResponse'
import { useMachine } from '@xstate/react'
import { citizenFormMachine } from '../../../utils/citizenFormMachine'
import Paginator from '../../molecules/Paginator'
import CitizenValidateEmailOrPhoneForm from './CitizenValidateEmailOrPhoneForm'
import { AsyncFormErrorProvider } from '../../../context/AsyncFormErrorProvider'

const CitizenFormContainer = ({ form, isPreview = false }) => {
  const [state, send] = useMachine(citizenFormMachine)
  const pageCount = form.max_steps
  useEffect(() => {
    send('SET_INITIAL_CONTEXT_WITH_EXTERNAL_DATA', {
      form_data: { ...form, isPreview }
    })
  }, [])
  return (
    <>
      <CitizenFormPageContainer show={state.matches('filling_form') || state.matches('saving_answer')} state={state}>
        <AsyncFormErrorProvider>
          <CitizenForm data={form} send={send} state={state} isPreview={isPreview} />
        </AsyncFormErrorProvider>
      </CitizenFormPageContainer>
      <CitizenFormPageContainer
        show={state.matches('validating_email_or_phone')}
        showContainerHeader={false}
        state={state}
      >
        <CitizenValidateEmailOrPhoneForm send={send} state={state} />
      </CitizenFormPageContainer>
      <CitizenFormPageContainer show={state.matches('paying')} state={state}>
        <PaymentMethodFormSelectionCitizen send={send} state={state} />
      </CitizenFormPageContainer>
      <CitizenFormPageContainer show={state.matches('response')} showContainerHeader={false} state={state}>
        <FormServerResponse answerCode={state.context.answer_data.hashid} formType={form.subtype} />
      </CitizenFormPageContainer>
      {isPreview && pageCount > 1 && !state.matches('response') && (
        <div className="max-w-screen-lg mx-auto mb-8">
          <Paginator
            className="flex justify-end"
            pageCount={pageCount}
            forcePage={state.context.activeStep}
            onPageChange={({ selected }) => {
              if (selected + 1 === pageCount && form.it_costs) {
                send('PAY_PREVIEW', { activeStep: selected })
              } else {
                send('GOTO_PREVIEW', { activeStep: selected })
              }
            }}
          />
        </div>
      )}
    </>
  )
}
export default CitizenFormContainer

CitizenFormContainer.propTypes = {
  form: PropTypes.object,
  isPreview: PropTypes.bool
}
