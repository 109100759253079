import { useEffect } from 'react'
import { useAsyncFormErrorContext } from '../../../context/AsyncFormErrorProvider'
import { validateUniqueDocument } from '../../../utils/validations'

export const useUniqueResponseValidation = (uniqueResponseQuestion, formId, docType, docId) => {
  const validate = validateUniqueDocument(formId, docType)
  const asyncFormErrorContext = useAsyncFormErrorContext()

  useEffect(() => {
    if (!uniqueResponseQuestion || !asyncFormErrorContext) {
      return
    }

    const { setAsyncError, asyncError } = asyncFormErrorContext
    validate(docId).then(validateResponse => {
      const newAsyncError = { ...asyncError }
      if (validateResponse) {
        newAsyncError.uniqueResponseValidation = validateResponse
      } else {
        delete newAsyncError.uniqueResponseValidation
      }
      setAsyncError(newAsyncError)
    })
  }, [docId, formId, docType])

  return asyncFormErrorContext?.asyncError?.uniqueResponseValidation
}
