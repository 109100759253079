import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import { HtmlField } from '../../molecules/fields/HtmlField'
import ScrollToTop from '../../molecules/ScrollToTop'
import FormSection from '../../molecules/FormSection'
import CheckboxField from '../../molecules/fields/CheckboxField'
import SaveButton from '../../molecules/buttons/SaveButton'
import { Form as FinalForm } from 'react-final-form'
import { focusOnFirstError } from '../../../utils/decorators'
import FormsHeader from '../../molecules/FormsHeader'
import { FORM_ERROR } from 'final-form'
import { options, icons } from '../../../configs/notificationsConfig'
import RadioField from '../../molecules/fields/RadioField'
import PreviewModal from './PreviewModal'
import Value from '../../molecules/Value'
import IconSelectorField from '../../molecules/fields/IconSelectorField'
import DateField from '../../molecules/fields/DateField'
import TimePickerField from '../../molecules/fields/TimePickerField'
import { useHistory } from 'react-router-dom'
import Error from '../../molecules/Error'
import { requiredArray } from '../../../utils/validations'
import { FormControl, FormLabel } from '@material-ui/core'

const Form = ({ headerInfo, initialValues, mutateForm }) => {
  const [uploadProgress, setUploadProgress] = useState(null)
  const [textButton, setTextButton] = useState(
    initialValues.send_type === 'scheduled' ? 'Programar envío' : 'Enviar notificación'
  )

  const history = useHistory()
  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  const handleSubmit = async values => {
    try {
      await mutateForm(
        values,
        {
          onSuccess: () =>
            textButton === 'Programar envío'
              ? history.push(`/notificaciones?action=notification_created&notification_sort=created_at desc`)
              : history.push(`/notificaciones?action=notification_send&notification_sort=created_at desc`),
          onError: error => window.alert(error.response.data.message || 'Hubo un error al actualizar la notificación')
        },
        {
          onUploadProgress: progressEvent =>
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
      )
    } catch (error) {
      if (error.response.data.error) {
        return { [FORM_ERROR]: error.response.data.error }
      }
    }
  }

  return (
    <>
      <FormsHeader title={headerInfo.title} hint={headerInfo.hint} classHint="mb-5 md:mb-8" />
      <FinalForm onSubmit={handleSubmit} initialValues={initialValues} decorators={[focusOnFirstError]}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <FormSection title="Configuración" className="js-field-container">
              <FormControl component="fieldset">
                <FormLabel
                  classes={{ root: 'block font-bold mb-1 min-w-100 js-field-container text-gray-800 text-base' }}
                >
                  Funcionarios que recibirán la notificación
                </FormLabel>
                {options.checkboxPermissions.map(permission => (
                  <CheckboxField
                    key={permission.code}
                    margin="none"
                    name="permissions"
                    value={permission.code}
                    label={permission.pluralName}
                    validate={requiredArray('Seleccione al menos una opción', 1)}
                    hideError
                  />
                ))}
                <Error name="permissions" />
              </FormControl>
              <RadioField
                containerClassName="mt-6 mb-2 w-56"
                margin="none"
                name="send_type"
                label="Hora de envío"
                options={options.validation}
                validate={validations.required()}
                onChange={e => {
                  setTextButton(e === 'scheduled' ? 'Programar envío' : 'Enviar notificación')
                }}
              />
              <Value name="send_type">
                {({ value: sendType }) =>
                  sendType === 'scheduled' && (
                    <div className="flex items-center ml-8">
                      <DateField
                        placeholder="Ingresar fecha"
                        name="date"
                        size="2xl"
                        containerClassName="-mb-0 min-w-64 mr-2"
                        validate={validations.required()}
                      />
                      <TimePickerField
                        emptyLabel="Selecciona una hora"
                        name="hour"
                        className="sm:max-w-sm"
                        validate={validations.required()}
                      />
                    </div>
                  )
                }
              </Value>
              <TextField
                rootClassName="mt-6"
                name="url_destination"
                label="URL destino"
                hint="Ingresa la URL de la página a donde redireccionará la notificación."
                size="2xl"
                validate={validations.mix(validations.required(), validations.isURL())}
              />
            </FormSection>
            <FormSection title="Contenido" className="-mb-0 md:-mb-0">
              <FormControl component="fieldset">
                <FormLabel classes={{ root: 'block font-bold mb-3 min-w-100 text-gray-800 text-base' }}>
                  Selecciona un ícono
                </FormLabel>
                <IconSelectorField options={icons} name="icon" />
              </FormControl>
              <HtmlField
                name="text"
                isBasic
                label="Texto de la notificación"
                maxContentLength={80}
                validate={validationsAdmin.isRichTextFieldEmpty()}
              />
            </FormSection>
            <div className="flex justify-end items-center mb-6 md:mb-0 md:mt-0 bg-blue-400 p-3 pr-8 md:pr-16 text-blue-700">
              <PreviewModal />
            </div>
            <FormSection>
              <div className="mb-3">
                <label className="font-bold text-xl md:text-2xl">
                  Enviar correo <span className="text-sm font-normal">(opcional)</span>{' '}
                </label>
              </div>
              <CheckboxField
                margin="none"
                name="has_email"
                label="Quiero enviar un correo junto con la notificación."
              />
              <Value name="has_email">
                {({ value: hasEmail }) =>
                  hasEmail && (
                    <div className="p-4">
                      <div className="bg-neutral-200 py-2 px-7">
                        <TextField
                          rootClassName="mt-6"
                          name="email_subject"
                          label="Asunto del correo"
                          size="2xl"
                          validate={validations.required()}
                        />
                        <HtmlField
                          name="email_content"
                          label="Contenido del correo"
                          validate={validations.mix(validations.required(), validationsAdmin.isRichTextFieldEmpty())}
                        />
                      </div>
                    </div>
                  )
                }
              </Value>
            </FormSection>
            <SaveButton
              label={textButton}
              containerClassName="px-6 md:px-0 m-auto md:mr-0"
              disabled={submitting}
              uploadProgress={uploadProgress}
            />
            <ScrollToTop />
          </form>
        )}
      </FinalForm>
    </>
  )
}
export default Form

Form.propTypes = {
  headerInfo: PropTypes.object,
  initialValues: PropTypes.object,
  mutateForm: PropTypes.func.isRequired
}
