import PropTypes from 'prop-types'
import React from 'react'
import { format } from 'date-fns'
import { Form as FinalForm } from 'react-final-form'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import * as validationsAdmin from '../../../utils/validationsAdmin'
import { HtmlField } from '../../molecules/fields/HtmlField'
import FormSection from '../../molecules/FormSection'
import CheckboxField from '../../molecules/fields/CheckboxField'
import SaveButton from '../../molecules/buttons/SaveButton'
import { focusOnFirstError } from '../../../utils/decorators'
import FormsHeader from '../../molecules/FormsHeader'
import Error from '../../molecules/Error'
import { options, frequencyOptions } from '../../../configs/announcementConfig'
import { requiredArray } from '../../../utils/validations'
import { FormControl, FormLabel } from '@material-ui/core'
import DateRangeField from '../../molecules/fields/DateRangeField'
import useInstitutionType from '../../../hooks/useInstitutionType'
import SelectField from '../../molecules/fields/SelectField'
import { dataToOptions } from '../../../utils/helpers'

const AnnouncementForm = ({ headerInfo, initialValues, onSubmit }) => {
  const today = format(new Date(), 'dd/MM/yyyy')
  const { getInstitutionTypes } = useInstitutionType()
  const { data: institutionTypes } = getInstitutionTypes()
  const institutionTypesWithAll = [{ value: 'all', label: 'Todos' }, ...dataToOptions(institutionTypes)]

  return (
    <>
      <FormsHeader title={headerInfo.title} hint={headerInfo.hint} classHint="mb-5 md:mb-8" />
      <FinalForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{ submitting: true, pristine: true }}
        decorators={[focusOnFirstError]}
      >
        {({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <FormSection title="Configuración" className="js-field-container">
              <HtmlField
                name="content"
                isBasic
                label="Texto del aviso"
                maxContentLength={120}
                validate={validationsAdmin.isRichTextFieldEmpty()}
                className="md:max-w-2xl"
              />
              <TextField
                name="url_destination"
                label="URL destino"
                hint="Ingresa la URL de la página a donde redireccionará el aviso."
                size="2xl"
                validate={validations.mix(validations.required(), validations.isURL())}
              />
              <DateRangeField
                name="date"
                label="Periodo de publicación"
                labelClassName="mt-3"
                minimumDate={today}
                isHorizontal
                validate={validations.required()}
              />
              <SelectField
                className="min-w-60"
                label="Tipo de entidad"
                name="institution_type_id"
                options={institutionTypesWithAll}
                validate={validations.required()}
              />
              <SelectField
                className="min-w-60"
                label="Frecuencia"
                name="frequency"
                options={dataToOptions(frequencyOptions)}
                validate={validations.required()}
              />
              <FormControl component="fieldset">
                <FormLabel
                  classes={{
                    root: 'block font-bold mb-1 min-w-100 js-field-container text-gray-800 hover:text-gray-800 text-base'
                  }}
                >
                  Destinatarios
                </FormLabel>
                {options.checkboxPermissions.map(permission => (
                  <CheckboxField
                    key={permission.code}
                    margin="none"
                    name="permissions"
                    value={permission.code}
                    label={permission.pluralName}
                    validate={requiredArray('Seleccione al menos una opción', 1)}
                    hideError
                  />
                ))}
                <Error name="permissions" />
              </FormControl>
            </FormSection>
            <SaveButton
              label={headerInfo.submitButton}
              containerClassName="px-6 md:px-0 m-auto md:mr-0"
              disabled={submitting || pristine}
            />
          </form>
        )}
      </FinalForm>
    </>
  )
}
export default AnnouncementForm

AnnouncementForm.propTypes = {
  headerInfo: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
}
