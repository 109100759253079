import React from 'react'
import PageHeading from '../../../molecules/PageHeading'
import { BoxTable } from '../../../molecules/BoxTable'
import useFilters from '../../../../hooks/useFilters'
import { urlFetch } from '../../../../hooks/useInstitution'
import InstitutionsTable from '../../../organisms/institutions/InstitutionsTable'
import InstitutionsFilter from '../../../organisms/institutions/InstitutionsFilter'

const sortOptions = [
  {
    value: 'name asc',
    label: 'Alfabético (A-Z)'
  },
  {
    value: 'name desc',
    label: 'Alfabético (Z-A)'
  }
]

const Institutions = () => {
  const {
    onFilter,
    initialFilters,
    results: institutions
  } = useFilters(`${urlFetch}/index_with_details.json`, { sort: 'name asc' })

  return (
    <>
      <PageHeading title="Entidades" pageDescription="Encuentra las entidades que usan Facilita." />
      <BoxTable
        initialFilters={initialFilters}
        onFilter={onFilter}
        filters={<InstitutionsFilter />}
        values={institutions}
        sortOptions={sortOptions}
        searchPlaceholder="Buscar por nombre de entidad"
        displayHeader={false}
        displayTotal
        displaySort
      >
        <InstitutionsTable institutions={institutions.data} />
      </BoxTable>
    </>
  )
}

export default Institutions
