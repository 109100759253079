import React from 'react'
import * as validations from '../../../../../utils/validations'
import TextField from '../../../../molecules/fields/TextField'
import CheckboxField from '../../../../molecules/fields/CheckboxField'
import Value from '../../../../molecules/Value'
import { useAuth } from '../../../../molecules/Auth'
import { useField } from 'react-final-form'

const SubscribedEmail = () => {
  const { user } = useAuth()
  const { input: subscribedEmail } = useField('subscribed_email')

  const handleChangeSubscribedEmail = checked => {
    subscribedEmail.onChange(checked ? user.email : null)
  }

  return (
    <>
      <CheckboxField
        name="has_subscribed_email"
        margin="none"
        label="Asignar correo para recibir respuestas."
        onChange={handleChangeSubscribedEmail}
      />
      <Value name="has_subscribed_email">
        {({ value: hasSubscribedEmail }) =>
          hasSubscribedEmail && (
            <TextField
              name="subscribed_email"
              margin="none"
              placeholder="Ej.: nombre@micorreo.com"
              validate={validations.mix(validations.required(), validations.isEmail())}
              rootClassName="ml-7"
            />
          )
        }
      </Value>
    </>
  )
}

export default SubscribedEmail

SubscribedEmail.propTypes = {}
