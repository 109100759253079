import PropTypes from 'prop-types'
import React from 'react'
import Alert from '../../molecules/Alert'
import Value from '../../molecules/Value'
import PaymentSetup from './PaymentSetup'

const PaymentMethodConfigurationSection = ({ hasPaymentMethodConfig, config }) => {
  return (
    <>
      {!hasPaymentMethodConfig && (
        <Alert
          className="py-3 max-w-lg flex flex-col md:ml-8"
          actionText="Ir a Medios de pago"
          actionTextClassName="md:ml-auto"
          actionEl="a"
          actionProps={{ href: '/admin/medios-de-pago', target: '_blank' }}
          border
        >
          <span className="font-normal mb-2 md:mb-0 -mt-1 inline-block">
            Para habilitar esta opción, activa la funcionalidad de pagos.
          </span>
        </Alert>
      )}
      <Value name="it_costs">
        {({ value: itCosts }) => itCosts && <PaymentSetup paymentMethods={config.enabled_payment_methods} />}
      </Value>
    </>
  )
}

export default PaymentMethodConfigurationSection

PaymentMethodConfigurationSection.propTypes = {
  hasPaymentMethodConfig: PropTypes.bool,
  config: PropTypes.object
}
