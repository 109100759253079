import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useMemo, useRef } from 'react'
import axios from 'axios'
import { useQuery } from 'react-query'
import { useCallbackOnFirstSuccess } from './useCallbackOnFirstSuccess'

const useFilters = (url, defaultFilters = {}, { pageName = 'page', onSuccessOnce = () => {} } = {}) => {
  const location = useLocation()
  const history = useHistory()
  const pageRef = useRef()
  const filters =
    location.search !== '' ? queryString.parse(location.search, { arrayFormat: 'bracket' }) : defaultFilters
  const initialFilters = useMemo(() => filters, [])
  const fetchData = search => axios.get(`${url}${search}`).then(a => a.data)

  const { data: results = { data: [], total_pages: 0, total_count: 0 }, status } = useQuery(
    [url, location.search],
    () => fetchData(location.search),
    { keepPreviusData: true, enabled: Boolean(url) }
  )

  useCallbackOnFirstSuccess(status, onSuccessOnce)

  const onFilter = (value, push = true) => {
    value[pageName] = 1
    pageRef.current = filters[pageName]

    const previousFilters = queryString.parse(location.search, { arrayFormat: 'bracket' })
    const currentAndPreviousFilters = { ...previousFilters, ...value }

    const effectiveFilters = {}
    for (const key in currentAndPreviousFilters) {
      if (currentAndPreviousFilters[key] !== '') {
        effectiveFilters[key] = currentAndPreviousFilters[key]
      }
    }

    const path = { search: queryString.stringify(effectiveFilters, { arrayFormat: 'bracket' }) }
    push ? history.push(path) : history.replace(path)
  }

  const clearUrlFilters = ({ keepParams = [] }) => {
    const initialResetFilter = {}
    keepParams.forEach(el => {
      if (filters[el]) {
        initialResetFilter[el] = filters[el]
      }
    })
    const path = { search: queryString.stringify(initialResetFilter, { arrayFormat: 'bracket' }) }
    history.push(path)
  }

  return { initialFilters, filters, results, status, onFilter, clearUrlFilters }
}
export default useFilters
