import React from 'react'
import Value from '../../../molecules/Value'
import TextField from '../../../molecules/fields/TextField'
import * as validations from '../../../../utils/validations'
import FieldCheckboxTitle from '../../../molecules/FieldCheckboxTitle'

const name = 'has_webhook'

const HasWebhook = () => {
  return (
    <>
      <FieldCheckboxTitle
        name={name}
        label="Enviar respuestas a API"
        description="Quiero que las respuestas de los ciudadanos se registren también en un API de mi institución."
      />
      <Value name={name}>
        {({ value }) =>
          value && (
            <div className="mt-2">
              <TextField
                name="webhook_url"
                size="5xl"
                rootClassName="ml-7"
                rows={6}
                margin="high"
                label="Ingresa la ruta"
                hint="Haremos un POST con content-type: application/json"
                validate={validations.mix(validations.required(), validations.isURL())}
              />
              <TextField
                name="webhook_auth"
                size="full"
                rootClassName="ml-7"
                rows={6}
                margin="none"
                hint="Lo enviaremos en el header authorization y así podrás comprobar que el envío viene desde Facilita"
                label="Ingresa el texto de autenticación"
                validate={validations.required()}
              />
            </div>
          )
        }
      </Value>
    </>
  )
}

export default HasWebhook

HasWebhook.propTypes = {}
