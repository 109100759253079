import PropTypes from 'prop-types'
import React from 'react'
import CategoryRow from './CategoryRow'
import useToggle from '../../../hooks/useToggle'
import { Button } from '../../atoms/Button'
import CategoriesNew from './CategoriesNew'
import AddIcon from '@material-ui/icons/Add'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'

const CategoriesTable = ({ categories = [] }) => {
  const [toggleCreate, setToggleCreate] = useToggle(false)

  return (
    <>
      <div className="pb-2 overflow-x-auto">
        <Table
          className="table-auto md:w-full"
          values={categories}
          head={
            <Tr body={false}>
              <Th className="min-w-32">Categoría</Th>
              <Th className="min-w-32">Cantidad de artículos que contiene</Th>
              <Th>Acciones</Th>
            </Tr>
          }
          body={categories.map((category, index) => (
            <CategoryRow key={category.id} index={index} categories={categories} />
          ))}
        />
      </div>
      {!toggleCreate ? (
        <Button className="max-w-full md:max-w-64 mt-4" size="full" variant="secondary" onClick={setToggleCreate}>
          <AddIcon />
          Crear categoría
        </Button>
      ) : (
        <CategoriesNew onFormSubmit={setToggleCreate} onCancel={setToggleCreate} />
      )}
    </>
  )
}

export default CategoriesTable

CategoriesTable.propTypes = {
  categories: PropTypes.array
}
